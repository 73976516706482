import React, { useState, useEffect } from 'react';

const LoadingBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1; // Adjust the increment for speed
      });
    }, 50); // Adjust the interval for loading speed

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      // Redirect to the /home directory
      window.location.href = '/home';
    }
  }, [progress]);

  return (
    <div style={styles.container}>
      <div style={styles.barContainer}>
        <div style={{ ...styles.bar, width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#fff', // White background
  },
  barContainer: {
    width: '25%', // Width of the loading bar container
    height: '20px', // Height of the loading bar
    backgroundColor: '#fff', // Light gray bar background
    borderRadius: '0px',
    overflow: 'hidden',
  },
  bar: {
    height: '100%',
    backgroundColor: '#000', // Black loading bar
    borderRadius: '0px',
    transition: 'width 0.05s ease-in-out', // Smooth loading animation
  },
};

const App = () => {
  return <LoadingBar />;
};

export default App;